import {Action, Selector, State, StateContext} from '@ngxs/store';
import {BaseService} from '@shared/services';
import {IService, IServiceBasicInformation, IServiceCreateUpdateDto, IServiceWithRelations} from '../interfaces';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {UtilityClass} from '@shared/utils/utility.class';
import {omit as _omit} from 'lodash';
import {environment} from '@env/environment';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {Injectable} from '@angular/core';

export interface IServiceState {
  services: IServiceWithRelations[];
  selected: string | null;
  count: number;

  servicesBasicInformation: IServiceBasicInformation[];
}

const _DEFAULT: IServiceState = {
  services: [],
  selected: null,
  count: 0,

  servicesBasicInformation: []
};

export namespace ServiceActions {
  export class Find {
    static readonly type: string = '[Service] Find';
    constructor(public filters: PrismaFilter<IServiceWithRelations>) {}
  }

  export class FindBasicInformation {
    static readonly type: string = '[Service] Find Basic Information';
    constructor(public filters: PrismaFilter<IServiceWithRelations>) {}
  }

  export class Count {
    static readonly type: string = '[Service] Count';
    constructor(public filters: PrismaFilter<IServiceWithRelations>) {}
  }

  export class FindById {
    static readonly type: string = '[Service] FindById';
    constructor(public id: string, public filters?: PrismaFilter<IServiceWithRelations>) {}
  }

  export class Post {
    static readonly type: string = '[Service] Post';
    constructor(public service: IServiceCreateUpdateDto) {}
  }

  export class CreateAsDraft {
    static readonly type: string = '[Service] Crearte As Draft';
    constructor(public service: IServiceCreateUpdateDto) {}
  }

  export class Patch {
    static readonly type: string = '[Service] Patch';
    constructor(public id: string, public service: IServiceCreateUpdateDto) {}
  }

  export class DeleteById {
    static readonly type: string = '[Service] DeleteById';
    constructor(public id: string) {}
  }

  export class Publish {
    static readonly type: string = '[Service] Publish';
    constructor(public id: string) {}
  }

  export class Draft {
    static readonly type: string = '[Service] Draft';
    constructor(public id: string) {}
  }


  export class Reject {
    static readonly type: string = '[Service] Reject';
    constructor(public id: string) {}
  }

  export class Reset {
    static readonly type: string = '[Service] Reset';
    constructor() {}
  }

}

@State<IServiceState>({
  name: 'ServiceState',
  defaults: _DEFAULT,
})
@Injectable()
export class ServiceState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getList({services}: IServiceState): IServiceWithRelations[] {
    return services;
  }

  @Selector()
  static getListBasicInformation({servicesBasicInformation}: IServiceState): IServiceBasicInformation[] {
    return servicesBasicInformation;
  }

  @Selector()
  static getSelected({services, selected}: IServiceState): IServiceWithRelations | null {
    return services.find(({id}): boolean => id === selected) ?? null;
  }

  @Selector()
  static getCount({count}: IServiceState): number {
    return count;
  }

  @Action(ServiceActions.Find)
  async find({patchState}: StateContext<IServiceState>, {filters}: ServiceActions.Find): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations[]> = await firstValueFrom(this.baseService.get<IServiceWithRelations[]>(`${this.SERVER}/services`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: response.entity!,
      selected: null,
    });
  }

  @Action(ServiceActions.FindBasicInformation)
  async findBasicInformation({patchState}: StateContext<IServiceState>, {filters}: ServiceActions.FindBasicInformation): Promise<void> {
    const response: DataBaseServiceResponse<IServiceBasicInformation[]> = await firstValueFrom(this.baseService.get<IServiceBasicInformation[]>(`${this.SERVER}/services/basic-information`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      servicesBasicInformation: response.entity ?? [],
    });
  }

  @Action(ServiceActions.FindById)
  async findById({patchState, getState}: StateContext<IServiceState>, {id, filters}: ServiceActions.FindById): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.get<IServiceWithRelations>(`${this.SERVER}/services/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selected: response.entity!.id!,
    });
  }

  @Action(ServiceActions.Count)
  async count({patchState}: StateContext<IServiceState>, {filters}: ServiceActions.Count): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IServiceWithRelations>(`${this.SERVER}/services/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }


  @Action(ServiceActions.Post)
  async post({patchState, getState}: StateContext<IServiceState>, {service}: ServiceActions.Post): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.post<IServiceWithRelations>(`${this.SERVER}/services`, _omit(service, ['id'])));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selected: response.entity!.id!,
    });
  }

  @Action(ServiceActions.CreateAsDraft)
  async createAsDraft({patchState, getState}: StateContext<IServiceState>, {service}: ServiceActions.CreateAsDraft): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.post<IServiceWithRelations>(`${this.SERVER}/services/draft`, _omit(service, ['id'])));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selected: response.entity!.id!,
    });
  }

  @Action(ServiceActions.DeleteById)
  async deleteById({patchState, getState}: StateContext<IServiceState>, {id}: ServiceActions.DeleteById): Promise<void> {
    const response: DataBaseServiceResponse<IService> = await firstValueFrom(this.baseService.delete<IService>(`${this.SERVER}/services/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    // patchState({
    //   services: UtilityClass.deleteItemByProp<IServiceWithRelations>(getState().services, 'id', id),
    //   selected: null,
    // });
  }

  @Action(ServiceActions.Publish)
  async publish({patchState, getState}: StateContext<IServiceState>, {id}: ServiceActions.Publish): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.post<IServiceWithRelations>(`${this.SERVER}/services/${id}/publish`, {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selected: response.entity!.id!,
    })
  }

  @Action(ServiceActions.Reject)
  async reject({patchState, getState}: StateContext<IServiceState>, {id}: ServiceActions.Reject): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.post<IServiceWithRelations>(`${this.SERVER}/services/${id}/reject`, {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selected: response.entity!.id!,
    })
  }

  @Action(ServiceActions.Draft)
  async draft({patchState, getState}: StateContext<IServiceState>, {id}: ServiceActions.Draft): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.post<IServiceWithRelations>(`${this.SERVER}/services/${id}/draft`, {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selected: response.entity!.id!,
    })
  }

  @Action(ServiceActions.Patch)
  async patch({patchState, getState}: StateContext<IServiceState>, {service, id}: ServiceActions.Patch): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.patch<IServiceWithRelations>(
      `${this.SERVER}/services/${id}`,
      _omit({
        ...service,
        approximatePriceRangeFrom: Number(service.approximatePriceRangeFrom),
        approximatePriceRangeTo: Number(service.approximatePriceRangeTo),
      }, ['organizationId', 'id'])
    ));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selected: response.entity!.id!,
    });
  }

}
